export default class AbstractCustomerSupportService {
	/**
	 * Sets the user information for the walkthrough widget.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	setWalkthroughWidgetUser() {
		throw new Error('Method setWalkthroughWidgetUser() must be implemented.');
	}

	/**
	 * Renders the walkthrough widget.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	renderWalkthroughWidget() {
		throw new Error('Method renderWalkthroughWidget() must be implemented.');
	}

	/**
	 * Returns a reference to the support widget component.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	getSupportWidgetComponent() {
		throw new Error(
			'Method getSupportWidgetComponent() must be implemented.',
		);
	}

	/**
	 * Returns the customer support email address.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	customerSupportEmail() {
		throw new Error('Method customerSupportEmail() must be implemented.');
	}

	/**
	 * Returns the customer support link.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	customerSupportLink() {
		throw new Error('Method customerSupportLink() must be implemented.');
	}
}
