/** @format */

import { observer } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';


export default Controller.extend({
	showChromeNotification: false,

	routing: service('-routing'),
	currentUser: service('current-user'),
	thirdParty: service(),
	notifications: service(),
	serviceWorker: service('service-worker'),
	session: service('session'),
	appSec: service(),
	mobile: service(),
	saasCustomerSupport: service('saas-customer-support'),
	featureGates: service('feature-gates'),

	serverDown: observer('serviceWorker.serverDown', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('serverDown')) {
			this.notifications.error(
				'App currently having trouble connecting. Try again in a few minutes.',
				{
					cssClasses: 'notification--failure',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),

	currentUserObserver: observer('currentUser.user', function () {
		if (!this.featureGates.isInitialized) return;

		this.saasCustomerSupport.renderWalkthroughWidget();
	}).on('init'),

	internetDown: observer('serviceWorker.internetDown', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('internetDown')) {
			this.notifications.error(
				'App currently having trouble connecting to internet. Please check your connection.',
				{
					cssClasses: 'notification--error',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),

	init() {
		this._super(...arguments);
		if (this.showMobile) {
			this.set('mobile.showMobile', true);
		}
	},

	actions: {
		logout() {
			this.currentUser.logout();
		},
	},
});
